.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  text-align: center;
  position: relative;
  background-color: #c2d3c4;
}

.spread-info,
.spread-selector {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
}
