/* Default layout */
.card-display {
  gap: 20px;
  position: relative;
  margin-bottom: 1em;
  z-index: 0;
}

.card-display.celtic-cross-layout {
  display: grid;
  grid-template-columns: 3.5fr 1fr;
}

/* Cross Wrapper */
.cross-wrapper {
  grid-column: 1 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  align-items: center;
  justify-items: center;
  grid-column: 1;
  grid-row: 1;
}

.card.position-1 {
  grid-column: 2;
  grid-row: 2;
}

.card.position-2 {
  grid-column: 2;
  grid-row: 2;
  z-index: 2;
}

.card.position-3 {
  grid-column: 2;
  grid-row: 1;
}
.card.position-4 {
  grid-column: 2;
  grid-row: 3;
}
.card.position-5 {
  grid-column: 1;
  grid-row: 2;
}
.card.position-6 {
  grid-column: 3;
  grid-row: 2;
}

/* Right Column Wrapper */
.right-column {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  align-items: center;
  justify-items: center;
  grid-column: 2;
  grid-row: 1;
}

.card {
  width: 100px;
  height: 150px;
  text-align: center;
  position: relative;
  cursor: pointer;
  height: auto;
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 0.5s ease-in,
    transform 0.5s ease-in;
  pointer-events: none;
  z-index: 1;
}

.card.visible {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.card.position-2.visible {
  transform: scale(1) rotate(90deg);
}

.card img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.position-meaning {
  font-size: 0.6em;
  color: red;
  margin-top: 5px;
  text-align: center;
  max-width: 100%;
  overflow-wrap: normal;
}

/* Responsive design */
@media (max-width: 1024px) {
  .card {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .card {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .card {
    width: 40%;
  }
}

/* Layout for non-Celtic Cross spreads (row layout) */
.card-display.row-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card-display.row-layout .card {
  display: flex;
  flex-direction: row;
  place-self: center;
  box-sizing: border-box;
  max-width: 150px;
}

.card-display.row-layout .card img {
  width: 100%;
  height: auto;
}
