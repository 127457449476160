.saved-typewriter {
  font-family: "Fanwood Text", serif;
  font-size: 25px;
  font-weight: bolder;
  text-align: left;
  mix-blend-mode: darken;
  --font-color: black;
  color: black;
  margin-bottom: 1em;
}
