.message-input-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.message-input {
  width: 100%;
  max-width: 80%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #4267b2;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  caret-color: black;
  transition: box-shadow 0.2s ease;
  resize: none;
  overflow: hidden;
  font-family: "EB Garamond";
}

.message-input:focus {
  border-color: #4267b2;
  box-shadow: 0 0 10px 5px rgba(66, 103, 178, 0.8);
}

.message-input::placeholder {
  color: #8a9ca9;
}

.below-text {
  font-size: 14px;
  font-style: italic;
  color: black;
  margin-top: 8px;
  display: flex;
  justify-content: left;
}

.button-container {
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
}

.esmeralda-message {
  font-family: "Fanwood Text", serif;
  font-size: 25px;
  font-weight: bolder;
  text-align: left;
  --font-color: black;
  color: black;
  margin-bottom: 1em;
}

.user-message {
  text-align: left;
  font-family: "EB Garamond";
  font-size: 16px;
  color: white;
  margin-bottom: 1.5em;
}

.card-display {
  margin-top: 1.5em;
}
