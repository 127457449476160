.navbar {
  background-color: black;
  color: white;
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-height: 100%;
  top: 0;
  z-index: 1000;
  position: sticky;
  overflow-y: visible;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  text-align: center;
}

.nav-links li {
  display: inline;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
}

.nav-links li:first-child {
  border-radius: 8px 8px 0 0;
}

.nav-links li:last-child {
  border-radius: 0 0 8px 8px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
}

.nav-links li:hover {
  background-color: #444;
  border-radius: 5px;
  box-shadow: 0 0 0 2.5px #444;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  z-index: 1000;
}

.dropdown-menu {
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  z-index: 1001;
}

.dropdown-menu li {
  padding: 10px;
  width: 100%;
  background-color: #444;
}

.dropdown-menu li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #555;
}

/* Responsive styles */
@media (max-width: 600px) {
  .nav-links a {
    font-size: 0.8rem;
  }

  .nav-links li div {
    font-size: 0.8rem;
  }
}

@media (max-width: 530px) {
  .nav-links a {
    font-size: 0.7rem;
  }

  .nav-links li div {
    font-size: 0.7rem;
  }
}
