/* Modal overlay for background fade */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

/* Modal fade-out effect */
.modal-overlay.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Modal content container */
.modal-content {
  background: #c5e1c5;
  width: 90%;
  max-width: 50vh;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}

/* Scrollbar styles for WebKit browsers */
.modal-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: transparent;
}

/* Close button */
.modal-close {
  position: relative;
  top: 1px;
  right: -15px;
  background: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

/* Modal image */
.card-detail {
  max-width: 50%;
  height: auto;
}

/* Action buttons */
.modal-content button {
  padding: 10px 20px;
  cursor: pointer;
  width: 90%;
}

/* Modal header styles */
.modal-header {
  margin-bottom: 20px;
}

.card-name {
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0.2rem;
  text-shadow:
    -0.3px 0,
    0 0.3px,
    0.3px 0,
    0 -0.3px,
    -0.3px -0.3px,
    0.3px 0.3px,
    -0.3px 0.3px,
    0.3px -0.3px;
}

.art-style {
  font-size: 0.9em;
  font-style: italic;
  color: #666;
}

.emoji-style {
  font-size: 1em;
  color: black;
  letter-spacing: 0.4rem;
  font-weight: bold;
}

.profile-picture-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 15px auto;
  text-align: center;
}

.profile-picture-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.profile-picture-placeholder.border-none {
  border: none;
}

.profile-picture-placeholder.border-dashed {
  border: 2px dashed #aaa;
}

.profile-picture {
  max-width: 100%;
  height: auto;
}

.profile-picture-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1px;
  flex-wrap: wrap;
}

.profile-picture-dual,
.profile-picture-placeholder {
  width: 190px;
  height: 329.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: #666;
  margin: 0 5px;
  border: 2px solid #ddd;
  box-sizing: border-box;
}

.dropdown {
  margin: 0 5px;
  padding: 5px;
  font-size: 1em;
}

.button-container {
  margin-top: 20px;
}
