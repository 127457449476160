h1,
h2,
h3,
p {
  color: white;
}

.home {
  margin: 20px;
  padding: 20px;
}

.home-header {
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 0.2rem;
  text-shadow:
    -1px 0,
    0 1px,
    1px 0,
    0 -1px,
    -1px -1px,
    1px 1px,
    -1px 1px,
    1px -1px;
}

.typewriter-effects {
  font-family: "Fanwood Text", serif;
  font-size: 25px;
  font-weight: bolder;
  text-align: left;
  mix-blend-mode: darken;
  --font-color: black;
  color: black;
  margin-bottom: 1em;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.spooky-button {
  background-color: #333;
  color: #fff;
  border: 2px solid #888;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "Fanwood Text", serif;
  cursor: pointer;
  transition: all 0.3s ease;
}

.spooky-button:hover {
  background-color: #444;
  border-color: #aaa;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.spooky-button:focus {
  outline: none;
}

.no-style-link {
  color: inherit;
  text-decoration: none;
}

.no-style-link:hover {
  color: inherit;
  text-decoration: none;
}

.esmeralda {
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.esmeralda.fade-in {
  opacity: 1;
}
