/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.create-account-modal {
  padding: 20px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.modal-name {
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0.2rem;
  text-shadow:
    -0.3px 0,
    0 0.3px,
    0.3px 0,
    0 -0.3px,
    -0.3px -0.3px,
    0.3px 0.3px,
    -0.3px 0.3px,
    0.3px -0.3px;
}

.explanation {
  font-family: "Fanwood Text", serif;
  color: black;
}

/* Tarot images */
.tarot-images {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 15px 0;
}

.tarot-images img {
  width: 100px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  position: relative;
  padding-right: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #777;
}

.error-text {
  color: #d9534f;
  font-size: 12px;
  padding-bottom: 20px;
}

/* Submit Button */
.submit-button {
  display: block;
  margin: auto;
  background: #6b9b37;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2rem;
  text-shadow:
    -0.3px 0,
    0 0.3px,
    0.3px 0,
    0 -0.3px,
    -0.3px -0.3px,
    0.3px 0.3px,
    -0.3px 0.3px,
    0.3px -0.3px;
}

.submit-button:disabled {
  background: #9e9e9e;
}

.submit-button:hover:enabled {
  background-color: #5a8b2b;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tarot-images img {
    width: 80px;
  }
}
