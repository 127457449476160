.favorites-container {
  padding: 20px;
}

.favorites-header {
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 0.2rem;
  text-shadow:
    -1px 0,
    0 1px,
    1px 0,
    0 -1px,
    -1px -1px,
    1px 1px,
    -1px 1px,
    1px -1px;
}

section {
  margin-bottom: 40px;
}

.favorites-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 280px;
}

.favorite-item {
  text-align: center;
}

.favorite-item img {
  width: 75%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

.favorite-label {
  color: black;
  font-weight: bold;
  font-family: "Fanwood Text", serif;
  margin-top: 20px;
}

.favorite-art-style {
  font-style: italic;
  font-family: "Fanwood Text", serif;
  color: #666;
  font-size: 0.9em;
}

.category-name {
  font-family: "Fanwood Text", serif;
  text-transform: uppercase;
}

.category-description {
  font-family: "Fanwood Text", serif;
  font-weight: normal;
}
